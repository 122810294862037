import { times, upperFirst, has, get, pick } from 'lodash';

import { TAX_NORMAL_TYPES, TAX_TYPES } from '@guestyci/shared-enums';

/* eslint-disable no-unused-expressions */
import currencySymbolMap from 'constants/currencies';
import { countryCodeToPhonePrefixMap } from 'constants/countryCodeToPhonePrefixMap';
import moment from 'moment';

import { DATE_FORMAT, PAYMENT_DATE_TYPE } from 'constants/date';

/* eslint-disable import/prefer-default-export */

/**
 * @description retrieves currency symbol from provided dictionary.
 * @param {string} currencyCode - key that corresponds particular currency symbol.
 * @param {object} currencySymbolMap - dictionary contains key/value currency symbols.
 * @returns {string} string that contains currency symbol.
 */
export function getSymbolFromCurrency(currencyCode, currencySymbolMapParam = {}) {
  if (typeof currencyCode !== 'string') return undefined;
  const code = currencyCode.toUpperCase();
  if (!Object.prototype.hasOwnProperty.call(currencySymbolMapParam, code)) return undefined;
  return currencySymbolMap[code];
}

/**
 * @description formats price value with appropriate currency symbol.
 * @param {any} price - price that has to be formatted with currency.
 * @param {string} currency - key that corresponds particular currency symbol.
 * @returns {string} string that contains price formatted with currency symbol.
 * @example €1,941.92
 */
export function formatPriceWithCurrency(price, symbol = '') {
  return price && `${symbol}${price.toLocaleString('en')}`;
}

/**
 * @description formats price value with appropriate currency symbol.
 * @param {number} price - price that has to be formatted with currency.
 * @param {string} currency - key that corresponds particular currency symbol.
 * @returns {string} string that contains price formatted with currency symbol.
 */
export function getPriceStr(price, currency) {
  return formatPriceWithCurrency(price, getSymbolFromCurrency(currency, currencySymbolMap));
}

export function formatTaxFeeTitle({ title, normalType, translatedLabel }) {
  const isTax = has(TAX_NORMAL_TYPES, normalType);
  const isCustomTaxName = isTax && !Object.values(TAX_TYPES).includes(title);
  if (isCustomTaxName) {
    return title;
  }
  return translatedLabel || upperFirst(title?.replaceAll('_', ' '));
}

export function generateSearchParams(
  {
    dates = {},
    location = {},
    guests,
    rooms,
    minPrice,
    maxPrice,
    currency,
    propertyType,
    tags,
    includeAmenities,
    numberOfBedrooms,
    numberOfBathrooms,
    sortBy,
    sortOrder,
  },
  searchParams = new URLSearchParams(window.location.search)
) {
  const { startDate = undefined, endDate = undefined } = dates;
  const { city = undefined, country = undefined } = location;
  const searchParamKeys = [
    'checkIn',
    'checkOut',
    'city',
    'country',
    'minOccupancy',
    'currency',
    'minPrice',
    'maxPrice',
    'propertyType',
    'tags',
    'includeAmenities',
    'numberOfBedrooms',
    'numberOfBathrooms',
    'lang',
    'rooms',
    'sortBy',
    'sortOrder',
  ];

  searchParamKeys.forEach((key) => searchParams.delete(key));

  if (city && country) {
    searchParams.append('city', city);
    searchParams.append('country', country);
  }

  if (guests && !Number.isNaN(Number(guests))) {
    searchParams.append('minOccupancy', guests);
  }

  if (startDate) {
    searchParams.append('checkIn', moment(startDate).format(DATE_FORMAT));
  }
  if (endDate) {
    searchParams.append('checkOut', moment(endDate).format(DATE_FORMAT));
  }

  if (minPrice) {
    searchParams.append('minPrice', minPrice);
  }

  if (maxPrice) {
    searchParams.append('maxPrice', maxPrice);
  }

  if (minPrice || (maxPrice && currency)) {
    searchParams.append('currency', currency);
  }

  if (propertyType) {
    searchParams.append('propertyType', propertyType);
  }

  if (tags) {
    searchParams.append('tags', tags);
  }

  if (includeAmenities) {
    searchParams.append('includeAmenities', includeAmenities);
  }

  if (numberOfBedrooms) {
    searchParams.append('numberOfBedrooms', numberOfBedrooms);
  }

  if (numberOfBathrooms) {
    searchParams.append('numberOfBathrooms', numberOfBathrooms);
  }

  if (rooms) {
    searchParams.append('rooms', rooms);
  }

  if (sortBy) {
    searchParams.append('sortBy', sortBy);
  }

  if (sortOrder) {
    searchParams.append('sortOrder', sortOrder);
  }

  return searchParams.toString();
}

function getGuestsItem(guests, maxGuests) {
  const value = String(guests);
  if (value === String(maxGuests)) {
    return { label: guests, value };
  }
  return { label: value, value };
}

export function getGuestItems(guestCount) {
  const arr = new Array(guestCount);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < guestCount; i++) {
    arr[i] = getGuestsItem(i + 1, guestCount);
  }
  return arr;
}

export function transformListingsMarkersResponse({ response = [] }) {
  return response.reduce((accumulator, { position, property }) => {
    if (!position?.lat || !position?.lng) {
      return accumulator;
    }

    const foundMarkersIndex = accumulator.findIndex(
      ({ position: { lng, lat } }) => position.lat === lat && position.lng === lng
    );

    if (foundMarkersIndex === -1) {
      accumulator.push({
        position: { lng: position.lng, lat: position.lat },
        properties: [property],
      });

      return accumulator;
    }

    accumulator[foundMarkersIndex].properties.push(property);

    return accumulator;
  }, []);
}

export function prettifyEnumText(text = '') {
  if (!text) {
    return '';
  }

  return (text.charAt(0) + text.slice(1).toLowerCase()).replace('_', ' ');
}

export function getKeyByValue(map, searchValue) {
  return [...map.entries()].find(([, val]) => val === searchValue)?.[0];
}

export function checkIsPhoneCodeOnly(phoneNumber) {
  return !!getKeyByValue(countryCodeToPhonePrefixMap, phoneNumber);
}

export function preparePhone(text) {
  const countryPhoneCode = checkIsPhoneCodeOnly(text);
  // don't send value if there is only country code
  if (countryPhoneCode) {
    return undefined;
  }

  return text && text.replace('+', '').replaceAll(' ', '');
}

export function createSingleReservationParams(values, ratePlanId, locale) {
  const { discounts = false, email, firstName, lastName, phone, message = null } = values;
  const createParams = {
    ratePlanId,
    guest: {
      firstName,
      lastName,
      email,
      phone: preparePhone(phone),
      preferredLanguage: locale,
    },
    policy: {
      privacy: {
        version: 1,
        dateOfAcceptance: moment().format(DATE_FORMAT),
        isAccepted: true,
      },
      marketing: {
        isAccepted: discounts,
      },
    },
    ...(message && {
      notes: {
        guest: message,
      },
    }),
  };

  return createParams;
}

export function createSingleInquiryReservationParams(values, ratePlanId, pointofsale, locale) {
  const { email, firstName, lastName, phone, discounts = false, message = null } = values;
  return {
    ratePlanId,
    guest: {
      firstName,
      lastName,
      phone: preparePhone(phone),
      preferredLanguage: locale,
      email,
    },
    policy: {
      privacy: {
        version: 1,
        dateOfAcceptance: moment().format(DATE_FORMAT),
        isAccepted: true,
      },
      marketing: {
        isAccepted: discounts,
      },
    },
    ...(message && {
      notes: {
        guest: message,
      },
    }),
    pointofsale,
  };
}

export function createGroupReservationParams({ values, rooms, quoteData, ratePlanId, additionalParams = {}, locale }) {
  const params = {
    quotes: values?.quotes || [],
    groupBooker: { ...values.groupBooker, preferredLanguage: locale },
    ...additionalParams,
  };

  const guestValues = ['firstName', 'lastName', 'email', 'phone'];

  const { groupBooker } = values;
  if (!params.quotes.length) {
    times(rooms, (i) => {
      params.quotes.push({
        guest: { ...pick(groupBooker, guestValues), preferredLanguage: locale },
        quoteId: get(quoteData.quote, `${i}._id`),
        ratePlanId,
      });
    });
  }
  return params;
}

export function isCloudinaryImage(url) {
  return url?.includes('cloudinary.com/') || url?.includes('assets.guesty.com/');
}

export function joinUrl(baseUrl, relativeUrl) {
  let joinedUrl = `${baseUrl.replace(/\/$/, '')}/${relativeUrl.replace(/^\//, '')}`;
  // Replace any occurrences of "//" with "/"
  joinedUrl = joinedUrl.replace(/\/\//g, '/');
  return joinedUrl;
}

/**
 * @description Determines the type of payment date based on the provided `after` value.
 * @param {number|undefined} after - The number of days after confirmation or undefined.
 * @returns {string} The type of payment date, which can be 'DATE', 'AT', or 'AFTER'.
 */
export const getPaymentDateType = (after) => {
  switch (true) {
    case after === undefined:
      return PAYMENT_DATE_TYPE.DATE;
    case after === 0:
      return PAYMENT_DATE_TYPE.AT;
    case after > 0:
      return PAYMENT_DATE_TYPE.AFTER;
    default:
      return PAYMENT_DATE_TYPE.DATE;
  }
};
